
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

























































































.hello {
  h3 {
    margin: 40px 0 0;
  }
  ul {
    padding: 0;
    list-style-type: none;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
}
