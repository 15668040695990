
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        











// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
